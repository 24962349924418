import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import { globalJson } from "global/global_json";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  categories = globalJson.clientSays_arr,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

    let MY_GLIDEJS = useMemo(() => {
      return new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 30, // Initial gap
        bound: true,
        breakpoints: {
          1440: {
            gap: 30,
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 30,
            perView: itemPerRow - 2,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 15,
            perView: itemPerRow - 2,
          },
          500: {
            gap: 10,
            perView: 1,
          },
          320: {
            gap: 10,
            perView: 1,
          },
        },
      });
    }, [UNIQUE_CLASS, itemPerRow]);
    

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (item: TaxonomyType, index: number) => {
    return <CardCategory5 taxonomy={item} />;
  };

  return (
    <div className={`nc-SectionSliderNewCategories rounded-none ${className}`}>
      <div
        className={`${UNIQUE_CLASS} flow-root bg-white  py-6 px-4 md:px-10 rounded-xl`}
      >
        <Heading desc={subHeading} hasNextPrev={sliderStyle === "style1"}>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {categories.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
